import { useTranslation } from "react-i18next";
// '@mui
import { enUS, /* deDE */ frFR } from "@mui/material/locale";

import MauritiusFlag from "./mauritius-image/flag-of-mauritius-logo.png";
import EnglishFlag from "./mauritius-image/flag-of-united-kingdom-logo.png";
import FrenchFlag from "./mauritius-image/flag-of-france-logo.png";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "Creole",
    value: "cr",
    systemValue: "",
    icon: MauritiusFlag,
  },
  {
    label: "French",
    value: "fr",
    systemValue: frFR,
    icon: FrenchFlag,
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: EnglishFlag,
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
