import _mock from "./_mock";
import _mockInvoicing from "./_mockInvoicing";
import _mockEcommerce from "./_mockEcommerce";
import { randomInArray } from "./funcs";

// ----------------------------------------------------------------------

export const _carouselsExample = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.text.title(index),
  image: _mock.image.feed(index),
  description: _mock.text.description(index),
}));

export const _carouselsPayroll = [...Array(5)].map((_, index) => ({
  id: _mockInvoicing.id(index),
  name: _mockInvoicing.name.fullName(index),
  role: _mockInvoicing.role(index),
  avatar: `https://minimal-assets-api.vercel.app/assets/images/members/member-${
    index + 1
  }.jpg`,
}));

export const _carouselsInvoicing = [...Array(5)].map((_, index) => ({
  id: _mockInvoicing.id(index),
  name: _mockInvoicing.name.fullName(index),
  role: _mockInvoicing.role(index),
  avatar: `https://minimal-assets-api.vercel.app/assets/images/members/member-${
    index + 1
  }.jpg`,
}));

export const _carouselsEcommerce = [...Array(5)].map((_, index) => ({
  id: _mockEcommerce.id(index),
  name: _mockEcommerce.name.fullName(index),
  role: _mockEcommerce.role(index),
  avatar: `https://minimal-assets-api.vercel.app/assets/images/members/member-${
    index + 1
  }.jpg`,
}));

// ----------------------------------------------------------------------

export const _invoice = {
  id: `${Date.now()}`,
  taxes: 5,
  discount: 10,
  status: "paid",
  invoiceFrom: {
    name: _mock.name.fullName(1),
    address: _mock.address.fullAddress(1),
    company: _mock.company(1),
    email: _mock.email(1),
    phone: _mock.phoneNumber(1),
  },
  invoiceTo: {
    name: _mock.name.fullName(2),
    address: _mock.address.fullAddress(2),
    company: _mock.company(2),
    email: _mock.email(2),
    phone: _mock.phoneNumber(2),
  },
  items: [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.text.title(index),
    description: _mock.text.description(index),
    qty: 5,
    price: _mock.number.price(index),
  })),
};

// ----------------------------------------------------------------------

export const _faqs = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Questions ${index + 1}`,
  detail: _mock.text.description(index),
}));

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  receiver: _mock.name.fullName(index),
  fullAddress: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  addressType: index === 0 ? "Home" : "Office",
  isDefault: index === 0,
}));

// ----------------------------------------------------------------------

export const _skills = [...Array(3)].map((_, index) => ({
  label: ["Development", "Design", "Marketing"][index],
  value: _mock.number.percent(index),
}));

// ----------------------------------------------------------------------

export const _accordions = [...Array(4)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Accordion ${index + 1}`,
  subHeading: _mock.text.title(index),
  detail: _mock.text.description(index),
}));

// ----------------------------------------------------------------------

export const _dataGrid = [...Array(36)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  lastLogin: _mock.time(index),
  performance: _mock.number.percent(index),
  rating: _mock.number.rating(index),
  status: randomInArray(["online", "away", "busy"]),
  isAdmin: _mock.boolean(index),
  lastName: _mock.name.lastName(index),
  firstName: _mock.name.firstName(index),
  age: _mock.number.age(index),
}));

// ----------------------------------------------------------------------

export const _megaMenuProducts = [...Array(10)].map((_, index) => ({
  name: _mock.text.title(index),
  image: _mock.image.feed(index),
  path: "#",
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  username: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  address: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  email: _mock.email(index),
  lastActivity: _mock.time(index),
  status: randomInArray(["online", "offline", "away", "busy"]),
  position: _mock.role(index),
}));

// ----------------------------------------------------------------------

export const _notifications = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    "Your order is placed",
    "Sylvan King",
    "You have new message",
    "You have new mail",
    "Delivery processing",
  ][index],
  description: [
    "waiting for shipping",
    "answered to your comment on the Minimal",
    "5 unread messages",
    "sent from Guido Padberg",
    "Your order is being shipped",
  ][index],
  avatar: [null, _mock.image.avatar(2), null, null, null][index],
  type: [
    "order_placed",
    "friend_interactive",
    "chat_message",
    "mail",
    "order_shipped",
  ][index],
  createdAt: _mock.time(index),
  isUnRead: [true, true, false, false, false][index],
}));

// ----------------------------------------------------------------------

export const _mapContact = [
  {
    latlng: [33, 65],
    address: _mock.address.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  },
  {
    latlng: [-12.5, 18.5],
    address: _mock.address.fullAddress(2),
    phoneNumber: _mock.phoneNumber(2),
  },
];
