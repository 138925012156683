import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Box, Container, Typography, Stack, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
// components
import Logo from "../../components/Logo";
//
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";
import moment from "moment";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  containerLogo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  containerCopyright: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export default function MainLayout() {
  const { t } = useTranslation();

  const classes = useStyles();

  const { pathname } = useLocation();

  const isHome = pathname === "/";

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {!isHome ? (
        <MainFooter />
      ) : (
        <Container>
          <Box
            sx={{
              py: 5,
              position: "relative",
              bgcolor: "background.default",
            }}
          >
            <Grid
              container
              spacing={3}
              style={{ minHeight: "15vh" }}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6} className={classes.containerLogo}>
                <Logo />
              </Grid>
              <Grid item xs={12} md={6} className={classes.containerCopyright}>
                <Typography
                  variant="body"
                  component="p"
                  sx={{ mx: "auto", color: "text.secondary" }}
                >
                  {t("copyright", {
                    year: `© ${moment(new Date()).format("YYYY")}`,
                  })}
                  {/*  {`Copyright © ${moment(new Date()).format(
                    "YYYY"
                  )} FerToSiteWeb Limited. All rights reserved.`} */}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Stack>
  );
}
